import axios from "axios"
import { store } from "../vuex/store"

// Registering axios globally 
// TODO: remove it in future
window.axios = axios

// Add a response interceptor
axios.interceptors.response.use(response => {
	// Any status code that lie within the range of 2xx cause this function to trigger
	return response
}, error => {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	if(error.response && (error.response.status == 401 || error.response.status == 419)){
		// Catch 401 and 419 error which means that session is over
		console.warn("User is not logged in. Redirecting to entrance")
		$main.redirectToEntrance()
		
		// Return an empty promise to stop all .catch() blocks to process 
		return new Promise(() => {})
	}

	// Some server troubles. Are we offline or server down?
	if(error.request && !error.response){
		store.commit('setOffline', true)

		// Return an empty promise to stop all .catch() blocks to process 
		return new Promise(() => {})
	}

	return Promise.reject(error)
})

export default axios;