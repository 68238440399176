import { Workbox } from 'workbox-window'

const PWA = {
	init: () => {
		if ('serviceWorker' in navigator) {
			const wb = new Workbox('/service-worker.js');
	
			// Add an event listener to detect when the registered
			// service worker has installed but is waiting to activate.
			wb.addEventListener('waiting', (event) => {
				// Assuming the user accepted the update, set up a listener
				// that will reload the page as soon as the previously waiting
				// service worker has taken control.
				wb.addEventListener('controlling', (event) => {
					window.location.reload()
				});
	
				wb.messageSkipWaiting()
			});

			wb.register()
		}

		// Prevent install button to showup
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault()
			
			window.deferredInstallPrompt = e // Put the prompt event for the later use
		})
	},
}

export default PWA;